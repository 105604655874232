import React from "react";
import loadable from "@loadable/component";
const ImageLoader = loadable(() => import("../common/ImageLoader"));
import { motion } from "framer-motion";
import { useIsMobile } from "../../hooks";

import awardsImgLg01 from "assets/images/landingAir/awards-credit/awards-img-01-lg.svg";
import awardsImgLg02 from "assets/images/landingAir/awards-credit/awards-img-02-lg.svg";
import awardsImgLg03 from "assets/images/landingAir/awards-credit/awards-img-03-lg.svg";

import awardsImgSm01 from "assets/images/landingAir/awards-credit/awards-img-01-sm.svg";
import awardsImgSm02 from "assets/images/landingAir/awards-credit/awards-img-02-sm.svg";
import awardsImgSm03 from "assets/images/landingAir/awards-credit/awards-img-03-sm.svg";

import awardsCelebrationGif from "assets/images/landingAir/awards-credit/awards-celebration.gif";

const awardsCreditImages = [
  {
    img: awardsImgLg01,
    imgSm: awardsImgSm01,
    alt: "100 Best InventionsTIME Magazine2023",
  },
  {
    img: awardsImgLg02,
    imgSm: awardsImgSm02,
    alt: "CES Innovation AwardsCES2024",
  },
  {
    img: awardsImgLg03,
    imgSm: awardsImgSm03,
    alt: "Best Air PurifierPopular Science2023",
  },
];

const AwardsItem = () => {
  const isMobile = useIsMobile(769);

  return (
    <div className="awards-credit__marquee-content">
      <div className="awards-credit__content-inner">
        <div className="awards-credit__logos">
          {awardsCreditImages.map((image, index) => (
            <ImageLoader
              key={image.alt}
              className="awards-credit__logo img-relative"
              placeholderAspectRatio={isMobile ? 220 / 59 : 410 / 123}
              svgSmUrl={image.imgSm}
              svgUrl={image.img}
              alt={image.alt}
            />
          ))}
        </div>
        <motion.div
          className="awards-credit__text"
          initial={{ opacity: 0, y: 20 }}
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.02 }}
          variants={{
            visible: { opacity: 1, y: 0 },
            hidden: { opacity: 0, y: 20 },
          }}
        >
          <p>
            <strong>CES Innovation Award Honoree</strong> — <em>CES</em> <br />
            <strong>Best of CES</strong> - <em>Apartment Therapy</em>
            <br />
            <strong>Best Smart Air Purifier</strong> - <em>Gear Patrol</em>
            <br />
            <strong>Best Air Purifier</strong> - <em>Popular Science</em>
            <br />
            <strong>2024 Gadget Award Winner</strong> - <em>Esquire</em>
            <br />
            <strong>Best Air Purifier</strong> - <em>Homes & Gardens</em>
            <br />
            <strong>Best Smart Air Purifier</strong> - <em>House Beautiful</em>
            <br />
            <strong>Innovation Honoree</strong> -{" "}
            <em>International Home Show</em>
            <br />
            <strong>Best Sleep Product</strong> - <em>House Beautiful</em>
            <br />
            <strong>Best of Show</strong> - <em>The Kitchn</em>
          </p>
        </motion.div>
      </div>
    </div>
  );
};

const AwardsCredit = () => {
  return (
    <section className="awards-credit">
      <span
        className="awards-credit__background"
        style={{ backgroundImage: `url(${awardsCelebrationGif})` }}
      ></span>
      <div className="container">
        <div className="awards-credit__header">
          <motion.h2
            className="awards-credit__title"
            initial={{ opacity: 0, y: 20 }}
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0 }}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 20 },
            }}
          >
            It’s no wonder she’s the Meryl Streep of air purifiers.
          </motion.h2>
        </div>
        <div className={`awards-credit__body`}>
          <div className="awards-credit__marquee-wrap marquee-inner">
            <div className="awards-credit__marquee marquee-content">
              <AwardsItem />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AwardsCredit;
